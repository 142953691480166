'use client';

import { useState, useTransition } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Alert, AlertDescription, AlertTitle } from '@kit/ui/alert';
import { Button } from '@kit/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@kit/ui/form';
import { Input } from '@kit/ui/input';
import { Trans } from '@kit/ui/trans';

import { SignupWaitlistSchema } from '../lib/schema/signup-waitlist.schema';
import { signUpToWaitlistAction } from '../lib/server/server-actions';

function WaitlistSignUpSuccess() {
  return (
    <Alert variant={'success'}>
      <AlertTitle>
        <Trans i18nKey={'auth:waitlist.success'} />
      </AlertTitle>

      <AlertDescription>
        <Trans i18nKey={'auth:waitlist.successDescription'} />
      </AlertDescription>
    </Alert>
  );
}

function WaitlistSignUpError() {
  return (
    <Alert variant={'destructive'}>
      <AlertTitle>
        <Trans i18nKey={'auth:waitlist.error'} />
      </AlertTitle>

      <AlertDescription>
        <Trans i18nKey={'auth:waitlist.errorDescription'} />
      </AlertDescription>
    </Alert>
  );
}

export function WaitlistSignupForm() {
  const [state, setState] = useState({
    error: '',
    success: false,
  });

  const [pending, startTransition] = useTransition();

  const form = useForm({
    resolver: zodResolver(SignupWaitlistSchema),
    defaultValues: {
      email: '',
    },
  });

  if (state.success) {
    return <WaitlistSignUpSuccess />;
  }

  if (state.error) {
    return <WaitlistSignUpError />;
  }

  return (
    <Form {...form}>
      <form
        className={'flex w-full flex-col space-y-4'}
        onSubmit={form.handleSubmit((data) => {
          startTransition(() => {
            void signUpToWaitlistAction(data)
              .then(() => {
                setState({
                  error: '',
                  success: true,
                });
              })
              .catch((error) => {
                setState({
                  error: error.message,
                  success: false,
                });
              });
          });
        })}
      >
        <FormField
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey={'auth:emailAddress'} />
                </FormLabel>

                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            );
          }}
          name={'email'}
        />

        <Button className="btn btn-primary text-white text-[16px] font-bold" disabled={pending}>
          <Trans i18nKey={'auth:waitlist.submitButton'} />
        </Button>
      </form>
    </Form>
  );
}
